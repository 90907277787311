import { height } from '@mui/system';
import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import img1 from '../asset/ATAL.jpeg'
import rgst from '../asset/rsz_rgst.png'
import rec from '../asset/rec.png'
import './Sample.css'
import Slider from '../Components/Slider/Slider'

const Sample = () => {
    const images = [
        "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
        "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
        "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    ];
    // padding: 20px;
    // font-size: 20px;
    // background: #efefef;
    // text-align: bottom;
  return(
    <>
    <Slider></Slider></>
  )
}

export default Sample