import { Link } from "@mui/material";
import React from "react";

const SocialIcons = ({ Icons }) => {
  return (
    <div className="text-lighttext">
      {Icons.map((icon) => (
        <span 
          key={icon.name}
          className="p-2 cursor-pointer inline-flex items-center
        rounded-full bg-textunder mx-1.5 text-xl"
        >
          <a href={icon.link}><img src={icon.name}/></a>
        </span>
      ))}
    </div>
  );
};

export default SocialIcons;