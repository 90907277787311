export const ABOUT = [
    { name: "Overview", link: "/#/overview" },
    { name: "Team", link: "/#/team" },
    { name: "Mission and Vision", link: "/#/missionvision" }
    
  ];
  export const BUSINESS = [
    { name: "Research", link: "/#/research" },
    { name: "Recognition", link: "/#/recognition" }
  ];
  
  
  export const Icons = [
    
    { name: "https://img.icons8.com/fluency/32/linkedin.png", link: "https://www.linkedin.com/company/gavyuti-agro-pvt-ltd-gapl/" },
    { name: "https://img.icons8.com/fluency/32/instagram-new.png", link: "https://www.instagram.com/gavyuti.agro/" },
    { name: "https://img.icons8.com/color/32/twitter--v1.png", link: "https://twitter.com/Gavyuti_Agro" },
    { name: "https://img.icons8.com/color/32/facebook.png", link: "#" },
  ];