import React from 'react'
import rgst from '../asset/RGST.PNG'
import Slider from '../Components/Slider/Slider'
import img4 from '../asset/bg2.png'
import img1 from "../asset/ATAL.jpeg"
import img2 from "../asset/rec.png"
import img3 from "../asset/rsz_rgst.png"

const Recognition = () => {
  return (
    <>
    <hr className=' mx-auto w-full h-1 rounded border-0  bg-navbg'></hr>
    <div className='mt-8 mb-20'>
      <div className='text-center font-extrabold text-4xl text-textunder'>
    Recognition
  </div>
  <Slider className="hidden md:block"/>
  <div className='mt-8 block md:hidden'>
    <img src={img1} className="border-solid border-4 border-navbg w-10/12 mx-auto items-center justify-center "/>
    <p className='text-lg text-center mt-2 font-bold'>Recognised by Atal IncubationCentre(AIC-RMP)</p>
    <hr className=' mx-auto  w-10/12 h-1 mt-4 rounded border-0  bg-navbg'></hr>
    <img src={img3} className="border-solid border-4 mt-6 border-navbg w-10/12 mx-auto items-center justify-center "/>
    <p className='text-lg text-center mt-2 font-bold'>Award by the hands of Padma Vibhushan - Dr. Anil Kakodkar</p>
    <hr className=' mx-auto  w-10/12 h-1 mt-4 rounded border-0  bg-navbg'></hr>
    <img src={img2} className="border-solid border-4 mt-6 border-navbg w-10/12 mx-auto items-center justify-center "/>
    <p className='text-lg text-center mt-2 font-bold'>Winner of Build 2.0 Regional organised by Boeing and SINE IIT Bombay</p>
  </div>
  
  </div>
    </>
  )
}

export default Recognition