import React from 'react'

const Careers = () => {
  return (
    <>
    <hr className=' mx-auto w-full h-1 rounded border-0  bg-navbg'></hr>
    <div className='mt-8'>
    <div className='text-center font-extrabold text-4xl text-textunder'>
    Career
  </div>
  <div className='m-auto lg:w-9/12 w-11/12 mt-12 p-4 justify-center items-center bg-navbg'>
    <div>
    <ul>
  <li>     
    <div className='ml-8 text-lighttext p-4 font-semibold text-2xl underline'>
    Company Culture
    </div>
    <div className='text-xl ml-12 p-2 font-semibold text-white'>
        Join our team at Gavyuti Agro and experience a
positive work environment where teamwork, collaboration, and
innovation thrive. We value our employees and foster a culture of
continuous learning, growth, and inclusivity.
    </div>
  </li>
  <li>
  <div className='ml-8 text-lighttext p-4 font-semibold text-2xl underline'>
  Current Job Openings
    </div>
    <div className='text-xl ml-12 p-2 font-semibold text-white '>
    <p className='underline'>
    1. Production Specialist (Agriculture Graduate, No of post - 01): 
    </p>
    <div className='ml-6 mt-2 text-lg mr-20'>
    Join us in
protecting the environment while ensuring the highest quality and nutritional
value in our complete feed pellets.
    </div>
    <p className='underline mt-4'>
    2. Interns ( Agriculture &amp; Veterinary , No of post -02 ):
    </p>
    <div className='ml-6 mt-2 text-lg mr-20'>
    Join our team and Gain
valuable hands-on experience in our innovative agriculture practices. Assist
our team in enhancing animal performance and reducing diseases through
proper nutrition and feed management. After successful completion of your
internship you may be hired as full time employee.
Keep Checking for our Social media pages for vacancies.
    </div>
 
    </div>
  </li>
  <li>
  <div className='ml-8 text-lighttext p-4 font-semibold text-2xl underline'>
  Employee Benefits and Development
    </div>
    <div className='text-xl ml-12 p-2 font-semibold text-white'>
    At Gavyuti Agro, we believe in
investing in our employees&#39; well-being and professional growth. Join us
to enjoy competitive compensation, comprehensive benefits, and access
to ongoing training and development programs that empower you to
reach your full potential.
    </div>
  </li>
  <li>
  <div className='ml-8 text-lighttext p-4 font-semibold text-2xl underline'>
  Application Process
    </div>
    <div className='text-xl ml-12 p-2 font-semibold text-white'>
    Ready to join our team? Applying is simple.
Submit your application, including your resume and cover letter, through
our online portal. Our dedicated recruitment team will carefully review
your qualifications, and qualified candidates will be contacted for further
steps in the selection process.
    </div>
  </li>
</ul>  
    </div>
  </div>
    </div>
    </>
  )
}

export default Careers