import React from 'react'
import bg from '../asset/bg1.jpg'
import img1 from '../asset/1st.png';
import img2 from '../asset/2nd.png';
import img3 from '../asset/3rd.png'
import img4 from '../asset/bg2.png'
import pro1 from '../asset/feed.jpg'
import pro2 from '../asset/pro2.png'
import pro3 from '../asset/pro3.png'
import pro4 from '../asset/pro4.png'
import patent from '../asset/patent2.png'
import patent2 from '../asset/CCF.jpeg'
import waste_to_wealth from '../asset/waste_to_wealth.png'

const LandingPage = () => {
  return (
    <>
    {/* background image and text */}
      <div className='w-full h-96 shadow-2xl overflow-hidden relative bg-gray-700 bg-opacity-25 '>

        <img src={bg}
          className='w-full h-96 absolute inset-0 object-cover ' />
        <div className='absolute inset-0 '></div>

        <div className='flex h-full items-center justify-center relative'>
          <h1 className='md:text-3xl text-xl sm:ml-16 text-textunder font-bold md:tracking-wider'>
            <h1 className='text-center md:text-7xl text-5xl text-white'>GAVYUTI AGRO</h1>
            <hr className='my-4 mx-auto w-6/12 h-1 bg-gray-100 rounded border-0 md:my-10 dark:bg-gray-700'></hr>
            <p className='text-lighttext italic text-2xl lg:text-4xl'>Farm's Waste to Farmer's Wealth</p>
          </h1>
        </div>
      </div>
    {/* 3 images */}
      <div className=' w-full h-full justify-center z-30 m-auto items-center relative -top-16 drop-shadow-xl rounded-2xl md:grid md:grid-cols-3'>
        <div className=''>
          <img src={img1} className=" hover:scale-110 ease-in duration-500 h-64 m-auto items-center justify-center rounded-2xl mb-4" />
          
        </div>

        <div className=''>
          <img src={img2} className=" hover:scale-110 ease-in duration-500 h-64 m-auto items-center justify-center rounded-2xl mb-4" />

        </div>

        <div className=''>
          <img src={img3} className="hover:scale-110 ease-in duration-500 h-64 m-auto items-center justify-center rounded-2xl" />

        </div>

      </div>

      {/* green background */}
      <div style={{ 'backgroundImage': `url(${img4})` }} className="h-40 bg-no-repeat -top-48 relative"></div>

      <div className='bg-navbg w-full -top-48 relative -mb-48'>
        <div class="p-4">
          {/* Our Patented Solution to Stop Crop Residue Burning  */}
        <h1 class="font-bold text-3xl text-center md:text-4xl md:mt-4 mb-4 text-white">
        Our Patented Solution to Stop Crop Residue Burning 
        </h1>
        <img src={patent} className='m-auto items-center justify-center hidden md:block ' ></img>
        <img src={patent2} className='m-auto items-center justify-center block md:hidden pb-10' ></img>
        {/* Crop residue to complete feed pellet  */}
        <h1 class="font-bold text-3xl text-center md:text-4xl md:mt-4 mb-4 text-white">
        Crop residue to complete feed pellet
        </h1>
        <img src={waste_to_wealth} className='m-auto items-center justify-center lg:w-2/3 w-full mb-16 border-2 border-solid' ></img>
          <h1 class="font-bold text-3xl text-center md:text-4xl md:mt-4 mb-4 text-white">Our Products</h1>

          {/* <!-- Card Container Start --> */}

          <div class="flex flex-wrap justify-center">

            <div class="flex flex-col bg-white rounded-lg shadow-md w-full m-6 overflow-hidden sm:w-52">

              <img src={pro1} alt="" class="h-40 " />

              <a href="#" class="bg-white text-black p-3 text-center hover:bg-lighttext transition-all duration-500 font-semibold">पूर्ण पशुखादय </a>

            </div>



            <div class="flex flex-col bg-white rounded-lg shadow-md w-full m-6 overflow-hidden sm:w-52">

              <img src={pro2} alt="" class="h-40 " />

              <a href="#" class="bg-white text-black p-3 text-center hover:bg-lighttext transition-all duration-500 font-semibold">सरकी पेंड</a>

            </div>



            <div class="flex flex-col bg-white rounded-lg shadow-md w-full m-6 overflow-hidden sm:w-52">

              <img src={pro3} alt="" class="h-40 " />

              <a href="#" class="bg-white text-black p-3 text-center hover:bg-lighttext transition-all duration-500 font-semibold">मक्का भरडा</a>

            </div>



            <div class="flex flex-col bg-white rounded-lg shadow-md w-full m-6 overflow-hidden sm:w-52">

              <img src={pro4} alt="" class="h-40 " />

              <a href="#" class="bg-white text-black p-3 text-center hover:bg-lighttext transition-all duration-500 font-semibold">कॅल्शियम & खनिज</a>

            </div>

          </div>

        </div>
      </div>




    </>
  )
}

export default LandingPage