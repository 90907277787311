import React from 'react'
import cattle from '../asset/cattle-modified.png'
import cow from '../asset/cattle.jpg'
import roadmap from '../asset/Roadmap.png'
import overview from '../asset/overview2.png'
import roadmap2 from '../asset/r1.png'
import roadmap3 from '../asset/r2.png'
import roadmap4 from '../asset/r3.png'
import roadmap5 from '../asset/r4.png'
import roadmap6 from '../asset/r5.png'

const Overview = () => {
  return (
    <>
    <hr className=' mx-auto w-full h-1 rounded border-0  bg-navbg'></hr>
    <div className='mt-8'>
      <div className='text-center font-extrabold text-4xl text-textunder'>
        Overview
      </div>


      <div className="mt-4 mb-10 mx-auto items-center justify-center p-4 md:p-0">
<div className=" flex flex-wrap w-full lg:w-4/5 mx-auto" >

  <div className="md:box-content lg:w-9/12 w-full lg:p-6 bg-nav-color text-white bg-navbg rounded-2xl my-10 font-general shadow-2xl mx-auto">
                    <div className="flex align-middle justify-center relative bottom-16">
                        <img className="w-48 h-48 rounded-full" src={cattle}></img>
                    </div>
                    <p className="text-md mt-0 md:mt-0 text-justify md:text-left text-xl p-5">
                    Gavyuti Agro (GA) is a pioneering company that has successfully
addressed three major challenges in the animal feed industry. By utilizing
non-fodder agriculture residue that would otherwise be burnt, GA minimizes
waste, protects the environment, and provides an additional source of income
to farmers. Our production of complete feed pellets from non-fodder residue
promotes sustainability by repurposing agricultural byproducts, reducing
environmental pollution, and ensuring optimal resource utilization. GA&#39;s
nutritionally balanced feed pellets simplify the feeding process for farmers
while supporting healthy growth and productivity in livestock. Overall, Gavyuti
Agro is revolutionizing the industry with its sustainable practices, economic
benefits, and commitment to stop the carbon emission at source. 
            </p>                    
                               
                </div>
  
  </div>

</div>


            <div className='text-center font-extrabold text-4xl text-textunder'>
            Our Journey So Far
      </div>
      <div className='mt-6 mb-5 container'>
        <img src={roadmap} className="hidden md:block p-4 items-center justify-center w-full h-1/6 lg:w-4/5 mx-auto"/>
        
        <img src={roadmap2} className="block md:hidden  p-4 items-center justify-center w-full h-1/6 lg:w-4/5 mx-auto"/>
        <img src={roadmap3} className="block md:hidden  p-4 items-center justify-center w-full h-1/6 lg:w-4/5 mx-auto"/>
        <img src={roadmap4} className="block md:hidden  p-4 items-center justify-center w-full h-1/6 lg:w-4/5 mx-auto"/>
        <img src={roadmap5} className="block md:hidden  p-4 items-center justify-center w-full h-1/6 lg:w-4/5 mx-auto"/>
        <img src={roadmap6} className="block md:hidden  p-4 items-center justify-center w-full h-1/6 lg:w-4/5 mx-auto"/>

        </div>

    </div>
    </>
  )
}

export default Overview