import React from 'react'
import vision from '../asset/m1.png'
import mission from '../asset/m2.png'

const MissionVision = () => {
  return (
    <>
    <hr className=' mx-auto w-full h-1 rounded border-0  bg-navbg'></hr>
    <div className='mt-8 mb-0'>
      <div className='text-center font-extrabold text-4xl text-textunder'>
    Mission & Vision
  </div>

  <div className='h-auto w-10/12 mt-2 mx-auto items-center justify-center gap-4 md:grid md:grid-cols-2'>
  <div className=''>
      <img src={mission} className="scale-100 object-fill relative mx-auto items-center justify-center "/>
      </div>
      <div className=''>
    <p className='text-3xl text-center mt-4 font-semibold underline underline-offset-8 decoration-textunder decoration-4'>
    Mission
      </p>
      <p className='lg:text-2xl text-xl text-center mt-4 font-semibold'>
      Our mission is to provide the highest quality animal feed products and services to our
customers, while also promoting the health and well-being of the animals in our care. We are
committed to using sustainable and environmentally responsible practices in all aspects of our
business, and to continuously improving and innovating in order to better serve our
customers and the animals they love.
      </p>
      </div>
  </div>

  <div className='hidden md:block mt-20 pb-16'>
  <div className='h-auto w-10/12 mx-auto lg:-mt-12 items-center justify-center gap-4 md:grid md:grid-cols-2'>
  <div className=''>
    <p className='text-3xl text-center mt-4 font-semibold underline underline-offset-8 decoration-textunder decoration-4'>
    Vision
      </p>
      <p className='text-2xl text-center mt-4 font-semibold'>
      Our vision is to be the leading provider of high-quality animal feed products and services, trusted
by customers and respected by the industry for our commitment to the health and well-being of
animals. We aim to set the standard for sustainability and responsibility in the animal feed industry,
and to be a driving force for positive change in the way that animals are cared for and nourished.
      </p>
      </div>
  <div className=''>
      <img src={vision} className="scale-100 object-fill relative items-center justify-center m-auto"/>
      </div>
      
  </div>
  </div>

  <div className='block md:hidden '>
  <div className='h-auto w-10/12 mx-auto lg:-mt-12 items-center justify-center gap-4 md:grid md:grid-cols-2'>
  <div className=''>
      <img src={vision} className="scale-90 object-fill relative "/>
      </div>
  <div className='mb-6'>
    <p className='text-3xl text-center mt-4 font-semibold underline underline-offset-8 decoration-textunder decoration-4'>
    Vision
      </p>
      <p className='text-xl text-center mt-4 font-semibold'>
      Our vision is to be the leading provider of high-quality animal feed products and services, trusted
by customers and respected by the industry for our commitment to the health and well-being of
animals. We aim to set the standard for sustainability and responsibility in the animal feed industry,
and to be a driving force for positive change in the way that animals are cared for and nourished.
      </p>
      </div>
  
      
  </div>
  </div>

  
    </div>
    </>
  )
}

export default MissionVision