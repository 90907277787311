import React from 'react'
import ItemsContainer from './ItemsContainer'
import SocialIcons from "./SocialIcons";
import { Icons } from "./Menus";

const Footer = () => {
  return (
    <footer className='bg-lighttext text-textunder text-xl'>
        
        {/* <ItemsContainer/> */}
      
        <div className='h-24 m-auto items-center lg:justify-between sm:justify-start text-xl text-center '>
        <p className=' ml-8'>Copyright &#169;. "Gavyuti Agro". All rights reserved</p>
        <SocialIcons className="" Icons={Icons} />
        </div>
    </footer>
  )
}

export default Footer