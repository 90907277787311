import React from 'react'
import img1 from '../asset/enviroment.png'
import img2 from '../asset/animal.png'
import img3 from '../asset/farmers.png'

const Research = () => {
  return (
    <>
    <hr className=' mx-auto w-full h-1 rounded border-0  bg-navbg'></hr>
    <div className='mt-8 '>
      <div className='text-center font-extrabold text-4xl text-textunder'>
    Research and Development
  </div>
  <div className='m-auto lg:w-9/12 w-11/12 mt-12 justify-center items-center bg-navbg'>
    <p className=' text-xl text-center mt-4 p-8 font-semibold text-white'>
    At Gavyuti Agro (GA), research and development (R&amp;D) is at the heart of our operations.
We are committed to continuous innovation and improvement in the animal feed industry.
Our R&amp;D efforts are focused on developing cutting-edge solutions that address key
challenges and deliver sustainable benefits to our customers and the environment.
    </p>

    {/* features */}
    <div className=' w-full h-full justify-center m-auto mt-4 items-center drop-shadow-xl rounded-2xl md:grid md:grid-cols-3 gap-4'>
        <div className=' m-auto justify-center items-center rounded-full'>
                        <img className="bg-white m-auto justify-center items-center w-28 h-28 rounded-full" src={img1}></img>     
                        <div className='bg-lighttext lg:w-80 mb-6 rounded-md'>
                          <p className='text-center p-4 font-semibold text-2xl underline'>To Protect Environment</p>
                          <p className='text-center font-normal text-lg p-2'>
                          Under the heading &quot;To Protect the Environment,&quot; we are dedicated to utilizing farm
residues to curb carbon emissions at the source. At GA, we understand the detrimental
impact of agricultural waste burning on the environment. By harnessing non-fodder and
fodder based agriculture residue , we minimize waste and contribute to reducing carbon
emissions. Our sustainable practices aim to preserve the environment for future
generations while ensuring the efficient use of available resources.
                          </p>
                          </div>    
        </div>

        <div className=' m-auto justify-center items-center rounded-full'>
                        <img className="bg-white m-auto justify-center items-center w-28 h-28 rounded-full" src={img2}></img>     
                        <div className='bg-lighttext lg:w-80 mb-6 rounded-md'>
                          <p className='text-center p-4 font-semibold text-2xl underline'>Animal Feed and Nutrition</p>
                          <p className='text-center font-normal text-lg p-2'>
                          We prioritize providing balanced diets to animals. Our complete feed pellets are formulated
to meet the specific nutritional requirements of different livestock species. By offering
optimal nutrition, we enhance animal performance, improve yield, and reduce the risk of
diseases. Through extensive research and development, we ensure that our feed products
are of the highest quality, promoting the health and well-being of the animals in our
customers&#39; care.
                          </p>
                          </div>    
        </div>
        <div className=' m-auto justify-center items-center rounded-full'>
                        <img className="bg-white m-auto justify-center items-center w-28 h-28 rounded-full" src={img3}></img>     
                        <div className='bg-lighttext lg:w-80 mb-6 rounded-md'>
                          <p className='text-center p-4 font-semibold text-2xl underline'>Farmers&#39; Efforts and Time</p>
                          <p className='text-center font-normal text-lg p-2 pb-16'>
                          Under the heading &quot;Farmers&#39; Efforts and Time,&quot; we recognize the challenges faced by
farmers in sourcing and formulating various feeds manually. Gavyuti Agro&#39;s complete feed
is a comprehensive solution that eliminates the need for farmers to gather multiple feed
sources. Our ready-to-use feed pellets save valuable time and effort, enabling farmers to
focus on other crucial aspects of their operations. 
                          </p>
                          
                          </div>    
        </div>
        
      </div>
      <p className=' text-xl text-center mt-4 p-8 font-semibold text-white'>
      In summary, Gavyuti Agro&#39;s R&amp;D initiatives drive our commitment to protecting the
environment, providing optimal animal feed and nutrition, and supporting farmers in their
efforts. Through continuous innovation and sustainable practices, we aim to make a
positive impact on the agricultural sector, enhance animal performance, and alleviate the
challenges faced by farmers.
    </p>
  </div>
 
  </div></>
  )
}

export default Research