import React from 'react'
import rajan from '../asset/rajan.png'
import profile from '../asset/profile.png'
import img4 from '../asset/bg2.png'

const Team = () => {
  return (
    <>
    <hr className=' mx-auto w-full h-1 rounded border-0  bg-navbg'></hr>
    <div className='mt-8'>
      <div className='text-center font-extrabold text-4xl text-textunder pb-8'>
        Our Team
      </div>
      <div className='w-11/12 lg:w-9/12 xl:gap-2 mt-8 z-30 relative  mx-auto items-center justify-center grid md:grid-cols-2 lg:gap-40 gap-6'>
    
      <div className=' mx-auto items-center justify-center'>  
            <div className='bg-lighttext pt-4 w-56 lg:w-80 h-32  relative lg:mb-10  hover:scale-110 ease-in duration-500  shadow-[0_0_22px_#3336]'>
            <p className='text-3xl text-center italic items-center justify-center m-auto  font-semibold'>
      Rajan Mishra 
      </p>
      <p className='text-xl text-center mt-2 font-bold'>
      Founder-IIT Bombay
      </p>
            </div>  
      </div>


      <div className=' mx-auto items-center justify-center'>  
            <div className='bg-lighttext pt-4 w-56 lg:w-80 h-32 relative lg:mb-10  hover:scale-110 ease-in duration-500  shadow-[0_0_22px_#3336]'>
            <p className='text-3xl text-center italic items-center justify-center m-auto  font-semibold'>
            Sourabh Singha
      </p>
      <p className='text-lg text-center mt-2 font-bold'>
      Technical head and Fund Raising 
      </p>
            </div>  
      </div>
   

  </div>
{/* ADVISOR */}
  <div style={{ 'backgroundImage': `url(${img4})` }} className="h-40 bg-no-repeat -top-12 relative"></div>
  <div className='bg-navbg w-full -top-16 relative -mb-16'>
        <div class="p-2 pb-6">

          <h1 class="font-bold text-3xl text-center md:text-4xl text-white">Advisors</h1>

          {/* <!-- Card Container Start --> */}
          <div className=' w-11/12 lg:w-10/12 mt-2  mx-auto items-center justify-center md:grid md:grid-cols-3'>
    <div className='h-28 m-2 p-2 lg:p-4 bg-lighttext lg:rounded-br-full lg:rounded-tl-full '>
      <p className='lg:text-3xl text-2xl text-center italic  font-semibold'>
      Dr Suhas Zambre 
      </p>
      <p className='text-2xl text-center mt-2 font-bold'>
      IIT Bombay 
      </p>
    </div>
    <div className='h-28 m-2 p-2 lg:p-4 bg-lighttext lg:rounded-br-full lg:rounded-tl-full'>
    <p className='text-2xl text-center italic  font-semibold'>
    Prof Madhu Vinjamur 
      </p>
      <p className='text-2xl text-center mt-2 font-bold'>
      IIT Bombay 
      </p>
    </div>
    <div className='h-28 m-2 p-2 lg:p-4 bg-lighttext lg:rounded-br-full lg:rounded-tl-full'>
    <p className='lg:text-3xl text-2xl text-center italic  font-semibold'>
    Dr Narendra Shah
      </p>
      <p className='text-2xl text-center mt-2 font-bold'>
      RGSTC Mumbai 
      </p>
    </div>
    <div className='h-28 m-2 p-2 lg:p-4 bg-lighttext lg:rounded-br-full lg:rounded-tl-full'>
    <p className='lg:text-3xl text-2xl text-center italic  font-semibold'>
    Dr Atul Dhok 
      </p>
      <p className='text-2xl text-center mt-2 font-bold'>
      MAFSU Nagpur
      </p>
    </div>

    <div className='h-28 m-2 p-2 lg:p-4 bg-lighttext lg:rounded-br-full lg:rounded-tl-full'>
    <p className='lg:text-3xl text-2xl text-center italic  font-semibold'>
    Dr Manoj Aware 
      </p>
      <p className='text-2xl text-center mt-2 font-bold'>
      BAIF Pune
      </p>
    </div>

  </div>
          

        </div>
      </div>


      

  
    </div>
    </>
  )
}

export default Team