import React, { useState, useEffect } from "react";
import "./Slider.css";
// import data from "./data";
import img1 from "../../asset/ATAL.jpeg"
import img2 from "../../asset/rec.png"
import img3 from "../../asset/rsz_rgst.png"

const data = [
  {
    id: 1,
    image: `${img3}`,
    title: "Award by the hands of Padma Vibhushan - Dr. Anil Kakodkar",
     },
  {
    id: 2,
    image: `${img1}`,
    title: "Recognised by Atal Incubation Centre(AIC-RMP)",
    },
  {
    id: 3,
    image: `${img2}`,
    title: "Winner of Build 2.0 Regional organised by Boeing and SINE IIT Bombay",
    },
  
];

const Slider = () => {
  const [people] = useState(data);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, people]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 5000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);

  return (
    <section className="section hidden md:block">
      
      <div className="section-center z-40 ">
        {people.map((item, indexPeople) => {
          const { id, image, name, title, quote } = item;
          let position = "nextSlide";
          if (indexPeople === index) {
            position = "activeSlide";
          }
          if (
            indexPeople === index - 1 ||
            (index === 0 && indexPeople === people.length - 1)
          ) {
            position = "lastSlide";
          }
          return (
            <article className={position} key={id}>
              <img src={image} alt={name} className="person-img mx-auto justify-center items-center" />
              
              <p className="text-base lg:text-xl z-40 text-center mt-2 font-bold">{title}</p>
              
            </article>
          );
        })}
        <button className="prev" onClick={() => setIndex(index - 1)}>
          <i className="fas fa-arrow-left" />
        </button>
        <button className="next" onClick={() => setIndex(index + 1)}>
          <i className="fas fa-arrow-right" />
        </button>
      </div>
    </section>
  );
};

export default Slider;
